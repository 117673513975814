<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentProfile /> 
</template>
<script>

import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentProfile from '@/components/team/dashboard/modules/setting/profile/ContentProfile.vue'

export default 
{
  name: 'Team-Profile',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentProfile
  }
}
</script>
<style>


</style>