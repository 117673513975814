<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <h3 class="mt-3 pb-0 border-bottom text-dark-blue">Profile </h3>
      <div class="row row-cols-1 row-cols-lg-12 align-items-stretch g-4 py-5">
        <div class="col">
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div class="container">
                <form class="row g-3 justify-content-center align-items-center">
                  <div class="col-md-4" align="center">
                    <img :src="profileInfo.picture" class="img-profile">
                  </div>
                  <div>
                    <h6>{{profileInfo.names}}</h6>
                  </div>
                  <h6 class="text-start cursor-text" @click="Open.picture = !Open.picture">
                    <i class="far fa-image"></i> Change Picture
                  </h6>
                  <div id="change" v-show="Open.picture" class="col-md-8 align-items-center">
                    <div class="inputfile-box">
                      <input type="file" id="file" class="inputfile" ref="fileInput" @change="pickFile">
                      <label for="file">
                        <span id="file-name" class="file-box"></span>
                        <span class="file-button">
                          <i class="fa fa-upload" aria-hidden="true"></i>
                          Choose a pic
                        </span>
                      </label>
                    </div>
                  </div>
                  <h6 class="text-start" @click="Open.name =! Open.name">
                    <i class="far fa-user"></i> Change Name
                  </h6>
                  <div id="change" v-show="Open.name" class="col-md-12 text-start">
                    <form @submit.prevent="ChangeName()" method="post">
                      <label class="form-label">Names</label>
                      <input class="form-control" type="text" id="names"  v-model="postFormName.names"  required>
                      <button class="mt-2 button-1 btn btn-xs btn-primary">Save</button>
                    </form>
                  </div>
                  <h6 class="text-start" @click="Open.email =! Open.email">
                    <i class="far fa-envelope"></i> Change Email
                  </h6>
                  <div id="change" v-show="Open.email" class="col-md-12 text-start">
                    <form @submit.prevent="ChangeEmail()" method="post">
                      <label class="form-label">New email </label>
                      <input class="form-control" type="email" id="email"  v-model="postFormEmail.email" required>
                      <label class="form-label">Confirm password </label>
                      <input class="form-control" type="password"  v-model="postFormEmail.password" id="pass" required>
                      <button class="mt-2 button-1 btn btn-xs btn-primary">Save</button>
                    </form>
                  </div>
                  <h6 class="text-start" @click="Open.pass =! Open.pass">
                    <i class="fas fa-unlock-alt"></i> Change Password
                  </h6>
                  <div id="change" v-show="Open.pass" class="col-md-12 text-start">
                    <form @submit.prevent="ChangePassword()" method="post">
                      <label class="form-label">Old Password </label>
                      <input class="form-control" type="password"  v-model="postFormPassword.old_password" id="old_pass" required>
                      <label class="form-label">New Password </label>
                      <input class="form-control" type="password"  v-model="postFormPassword.new_password" id="new_pass" required>
                      <button class="mt-2 button-1 btn btn-xs btn-primary">Save</button>
                    </form>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request';
  
  export default 
  {
    name: 'ContentProfile',
    data: () => (
    {
      postForm:
      {
        image:""
      },
      postFormName:
      {
        names:"",
      },
      postFormEmail:
      {
        email:"",
        password:""
      },
      postFormPassword:
      {
        old_password:"",
        new_password:""
      },
      profileInfo: 
      {
        names:"",
        picture:""
      },
      Open:
      {
        picture: false,
        pass: false,
        email: false,
        name: false,
        desactivate: false
      },
      Image: 
      {
        previewImage:""
      },
    }),
    methods: 
    {
      selectImage () 
      {
        this.$refs.fileInput.click()
      },
      pickFile () 
      {
        let input = this.$refs.fileInput
        let file = input.files
        
        if (file && file[0]) 
        {
          let reader = new FileReader
          reader.onload = e => 
          {
            this.profileInfo.picture = e.target.result
            this.postForm.image = this.profileInfo.picture

            Api.post("login/upload",this.postForm).then((result) => 
            {
              if(result.data.status == "true")
              {
                localStorage.setItem('picture', result.data.url)
                let message = result.data.msg
                this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
              }
            })
          }
          
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
        }
      },
      GetBase64Img ()
      {
        const file = document.querySelector('input[type=file]').files[0]
        const reader = new FileReader()
        let rawImg;

        reader.onloadend = () => 
        {
          rawImg = reader.result;
          this.postForm.image =  rawImg
          //console.log(rawImg);
        }
        
        reader.readAsDataURL(file);
      },
      Profile ()
      {
        this.profileInfo.names =  localStorage.getItem('names')
        this.postFormName.names =  localStorage.getItem('names')
        this.profileInfo.picture =  localStorage.getItem('picture')
      },
      ChangeEmail()
      {  
        Api.post("login/email",this.postFormEmail).then((result) => 
        {    
          if(result.data.status == "true")
          {
            const message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }

          if(result.data.status == "false")
          {
            const message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }
        })
      },
      ChangePassword()
      {  
        Api.post("login/password",this.postFormPassword).then((result) => 
        { 
          if(result.data.status == "true")
          {
            let message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }
          
          if(result.data.status == "false")
          {
            let message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }
        })
      },
      ChangeName()
      {  
        Api.post("login/name",this.postFormName).then((result) => 
        {    
          if(result.data.status == "true")
          {
            let message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
              
            localStorage.setItem('names', this.postFormName.names)
            this.Profile()
          }
            
          if(result.data.status == "false")
          {
            let message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }
        })
      }
    },
    mounted()
    {
      this.Profile() 
    }
  }
</script>

<style  scoped>
  .cursor-text
  {
    cursor: pointer;
  }
</style>
